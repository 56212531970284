import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'

import { toast } from '../../components/LsToast'
import { ScreenWrapper } from '../../components/ScreenWrapper'
import { TableSkeleton } from '../../components/TableSkeleton'
import { PartnerDto, PartnersSummaryOrderDto, ProductDto } from '../../generated/api/breadlove'
import { getPartnersSummaryOrders } from '../../service/orders.service'
import { getPartners } from '../../service/partners.service'
import { getProducts } from '../../service/products.service'
import { useStyles } from './styles'

const formatter = new Intl.DateTimeFormat('cs-CZ', { month: 'long', year: 'numeric' })

export const PartnersSummary: FC = () => {
  const styles = useStyles()
  const { t } = useTranslation()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [date, setDate] = useState<Date>(new Date())
  const [productsLoading, setProductsLoading] = useState<boolean>(true)
  const [partnersLoading, setPartnersLoading] = useState<boolean>(true)
  const [weeklySummaryLoading, setWeeklySummaryLoading] = useState<boolean>(true)
  const [products, setProducts] = useState<ProductDto[]>([])
  const [partners, setPartners] = useState<PartnerDto[]>([])
  const [partnersSummary, setPartnersSummary] = useState<PartnersSummaryOrderDto[]>([])

  const loadProducts = async () => {
    setProductsLoading(true)
    try {
      const res = await getProducts(new Date())
      setProducts(res.data)
    } catch {
      toast.error(t('general.notification.unexpectedError'))
    } finally {
      setProductsLoading(false)
    }
  }

  const loadPartners = async () => {
    setPartnersLoading(true)
    try {
      const res = await getPartners()
      setPartners(res.data)
    } catch {
      toast.error(t('general.notification.unexpectedError'))
    } finally {
      setPartnersLoading(false)
    }
  }

  const loadPartnersSummary = async () => {
    setWeeklySummaryLoading(true)
    try {
      const res = await getPartnersSummaryOrders(date)
      setPartnersSummary(res.data)
    } catch {
      toast.error(t('general.notification.unexpectedError'))
    } finally {
      setWeeklySummaryLoading(false)
    }
  }

  useEffect(() => {
    void loadProducts()
    void loadPartners()
  }, [])

  useEffect(() => {
    void loadPartnersSummary()
  }, [date])
  return (
    <ScreenWrapper
      title={t('partnersSummary.title')}
      filter={
        <Box display="flex" justifyContent="space-between" alignItems="center" gap={1}>
          <Button
            variant="outlined"
            aria-label="before"
            size="medium"
            onClick={() => {
              const d = new Date(date)
              d.setMonth(d.getMonth() - 1)
              setDate(d)
            }}
          >
            <KeyboardArrowLeftIcon />
          </Button>
          <TextField
            size="small"
            variant="filled"
            value={formatter.format(date)}
            sx={{
              '& .MuiInputBase-input': {
                paddingTop: '9px',
                paddingBottom: '8px',
                textTransform: 'capitalize',
              },
            }}
          />
          <Button
            variant="outlined"
            aria-label="next"
            size="medium"
            onClick={() => {
              const d = new Date(date)
              d.setMonth(d.getMonth() + 1)
              setDate(d)
            }}
          >
            <KeyboardArrowRightIcon />
          </Button>
        </Box>
      }
    >
      <Box mt={2}>
        {!partnersLoading && !productsLoading && !weeklySummaryLoading ? (
          <TableContainer component={Paper} sx={{ marginBottom: '80px' }}>
            <Table sx={{ minWidth: 650 }}>
              <TableHead>
                <TableRow>
                  <TableCell sx={styles.header}>{t('partnersSummary.table.partner')}</TableCell>
                  {products.map((product) => (
                    <TableCell key={`h${product.id}`} sx={styles.header} align="right">
                      {product.name}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {partners.map((partner) => (
                  <TableRow key={partner.id}>
                    <TableCell component="th" scope="row" sx={styles.header}>
                      {partner.name}
                    </TableCell>
                    {products.map((product) => (
                      <TableCell key={`b${product.id}`} sx={styles.cell} align="right">
                        {partnersSummary.find(
                          (summary) => summary.partnerId === partner.id && summary.productId === product.id
                        )?.count || 0}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <TableSkeleton />
        )}
      </Box>
    </ScreenWrapper>
  )
}
