import { useMemo } from 'react'
import * as Yup from 'yup'

import { OrderDtoPickupTimeEnum } from '../../../generated/api/breadlove'
import { prepareCustomMessages } from '../../../yup'

prepareCustomMessages()

export const useSchema = () => {
  return useMemo(
    () =>
      Yup.object().shape({
        type: Yup.string().required(),
        name: Yup.string()
          .max(255)
          .when('type', {
            is: (type: string) => 'b2c' === type,
            then: (schema) => schema.required(),
          }),
        phoneNumber: Yup.string()
          .test('empty-or-matches', 'Text není platný podle regulárního výrazu', function (value) {
            return (
              value === undefined ||
              value === null ||
              value === '' ||
              /^(\+\d{1,4}\s?)?(\d{3}\s?\d{3}\s?\d{3})$/.test(value)
            )
          })
          .max(255),
        email: Yup.string().optional().email().max(255),
        note: Yup.string().optional().max(255),
        pickupPointId: Yup.number().required(),
        pickupDate: Yup.date().required(),
        pickupTime: Yup.mixed<OrderDtoPickupTimeEnum>()
          .nullable()
          .optional()
          .test((value) => !value || Object.values(OrderDtoPickupTimeEnum).includes(value as OrderDtoPickupTimeEnum)),
        products: Yup.array()
          .required()
          .of(
            Yup.object().shape({
              productId: Yup.number().required(),
              count: Yup.number().required(),
            })
          ),
        partnerId: Yup.number().optional(),
      }),
    []
  )
}
